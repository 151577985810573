@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens";
@import "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens";
@import "app/javascript/stylesheets/variables/z-indexes.scss";

.card {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-border-default;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-block-end: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
  &:hover {
    background-color: $canopy-color-interactive-background-secondary-hover;
    border: 1px solid $canopy-color-primitives-gray-48;
  }
}

.bottom-card-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-block-start: $canopy-space-8x;
}

.link-container {
  &:hover {
    text-decoration: none;
  }
}

.patient-name {
  @include canopy-typography-heading-medium;
  color: $canopy-color-text-primary;
  padding-inline-end: $canopy-space-2x;
  margin-block-end: 0;
}

.patient-info {
  @include canopy-typography-body-small;
  color: $canopy-color-text-secondary;
  margin-block-end: $canopy-space-8x;
}

.detail-header {
  @include canopy-typography-body-xsmall;
  color: $canopy-color-text-secondary;
  margin-block-end: 0;
}

.detail-content {
  @include canopy-typography-body-small;
  color: $canopy-color-text-primary;
}

.chip {
  border: 1px solid $canopy-color-primitives-gray-32 !important;
  border-radius: 4px !important;
  color: $canopy-color-text-secondary !important;
  cursor: pointer !important;
  font: $canopy-typography-heading-small-font !important;
  height: 26px !important;
  padding-right: $canopy-space-2x !important;
  padding-left: $canopy-space-2x !important;
  letter-spacing: 0 !important;
  margin-bottom: $canopy-space-2x;
  max-width: 100%;
}

.chip-label-override {
  padding: 4px 6px !important;
  &.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.warning-chip {
  color: $canopy-color-primitives-yellow-48 !important;
  border-color: $canopy-color-primitives-yellow-48 !important;
}

.success-chip {
  color: $canopy-color-text-success !important;
  border-color: $canopy-color-text-success !important;
}

.error-chip {
  color: $canopy-color-text-danger !important;
  border-color: $canopy-color-text-danger !important;
}

.snooze-chip {
  background-color: $canopy-color-primitives-gray-24 !important;
  border-color: $canopy-color-primitives-gray-24 !important;
  color: $canopy-color-text-inverse !important;
}

.solid-warning-chip {
  @include canopy-typography-heading-small;
  background-color: $canopy-color-primitives-yellow-92 !important;
  border-color: $canopy-color-primitives-yellow-92 !important;
  color: $canopy-color-text-primary !important;
}

.signature-unknown-chip {
  @include canopy-typography-heading-small;
  background-color: $canopy-color-primitives-gray-94 !important;
  border-color: $canopy-color-primitives-gray-94 !important;
  color: $canopy-color-text-primary !important;
}

.last-comment-container {
  background-color: $canopy-color-background-primary;
  border: 1px solid $canopy-color-border-default;
  border-radius: 16px 16px 16px 0;
  margin-block-start: $canopy-space-8x;
  padding: $canopy-space-8x;
}

.last-comment-by-current-employer {
  border-radius: 16px 16px 0;
}

.unread-comment {
  border-color: $canopy-color-brand-primary;
}

.comment-badge {
  background-color: $canopy-color-interactive-background-primary-default;
  border-radius: 100%;
  color: $canopy-color-text-inverse;
  font-size: 0.75rem;
  font-weight: bold;
  height: 2rem;
  line-height: 2rem;
  position: absolute;
  right: -1rem;
  text-align: center;
  top: -1rem;
  width: 2rem;
}

.last-comment-header {
  @include canopy-typography-body-xsmall;
  color: #223036;
  font-weight: $canopy-typography-font-weight-bold;
}

.last-comment-date {
  @include canopy-typography-body-xsmall;
  color: $canopy-color-text-secondary;
}

.mention {
  background-color: $canopy-color-primitives-gray-86;
  padding: 1px;
}

.current-employment-mention {
  background-color: $canopy-color-background-discovery;
}

.last-comment-body {
  @include canopy-typography-body-small;
  color: #223036;
}

.followers-container {
  justify-content: flex-start;
}

.follower-chips-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 30px);
  column-gap: 2px;
}

.follower-chip {
  font: $canopy-typography-body-small-font !important;
  border-radius: 16px !important;
  height: 17px !important;
  width: 30px !important;
  pointer-events: none;
}

.follower-self-chip {
  font: $canopy-typography-heading-small-font !important;
  color: $canopy-color-interactive-background-primary-default !important;
  border: 1px solid $canopy-color-interactive-background-primary-default !important;
}

.follower-chip-label {
  padding-left: $canopy-space-2x !important;
  padding-right: $canopy-space-2x !important;
  text-overflow: initial !important;
  overflow: initial !important;
}

.follower-self-chip-label {
  padding-top: 3px !important;
}

.signature-and-document-status {
  display: flex;
  gap: $canopy-space-4x;
}

.bottom-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.stack-chips {
  flex-direction: column;
}

.actions-button {
  height: 32px;
  min-height: auto;
  position: relative;
  z-index: 2;
}

.open-actions-button {
  z-index: 3;
}

.actions-dropdown-container {
  margin-left: auto;
  position: relative;
}

.overlay {
  background: transparent;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: default;
}

.actions-container {
  border: 1px solid $canopy-color-border-default;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 $canopy-color-primitives-gray-86;
  position: absolute;
  z-index: $z-index-tile-overlay;
}

.order-action {
  @include canopy-typography-body-small;
  background-color: $canopy-color-background-secondary;
  border-radius: 4px;
  border-width: 0;
  color: $canopy-color-text-primary;
  padding: $canopy-space-6x $canopy-space-8x;

  &:hover {
    background-color: $canopy-color-interactive-background-secondary-hover;
    cursor: pointer;
  }
}
