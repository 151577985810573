@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.cardContent {
  width: 100%;
}

.container {
  border-radius: $canopy-space-2x;
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 1px solid $canopy-color-border-default;
  background: $canopy-color-background-secondary;
  margin-top: $canopy-space-8x;
}

.card {
  display: flex;
  flex: 1;
  // min-width: 340px;
  overflow: hidden;
  position: relative;
}

.withLeftBorder {
  border-left: 1px solid $canopy-color-border-default;
}
